<template>
  <ul id="bigScreen-filter">
    <li>
      <el-select v-model="category"  placeholder="种类">
        <el-option
            v-for="item in categoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </li>
    <li v-if="category==='交通枢纽'">
      <el-select v-model="transportation"  clearable placeholder="交通枢纽">
        <el-option
            v-for="item in transportationList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </li>
    <li v-if="category==='文教卫旅'">
      <el-select v-model="culture"  clearable placeholder="文教卫旅">
        <el-option
            v-for="item in cultureList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </li>
    <li v-if="category==='商业综合体'">
      <el-select v-model="businessCircleId"  clearable placeholder="商圈">
        <el-option
            v-for="item in businessList"
            :key="item.businessCircleId"
            :label="item.businessCircleName"
            :value="item.businessCircleId">
        </el-option>
      </el-select>
    </li>
    <li>
      <el-select v-model="parkingLotId"  clearable
                 placeholder="停车场">
        <el-option
            v-for="item in parkingLotList"
            :key="item.parkingLotId"
            :label="item.parkingLotName"
            :value="item.parkingLotId">
        </el-option>
      </el-select>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      category: '商业综合体',
      //交通枢纽
      transportation: '',
      //文教卫旅
      culture: '',
      //商圈
      businessCircleId: '',
      parkingLotId: '',
      categoryList:[
        {
          value: '交通枢纽',
          label: '交通枢纽',
        },
        {
          value: '文教卫旅',
          label: '文教卫旅',
        },
        {
          value: '商业综合体',
          label: '商业综合体',
        }
      ],
      transportationList:[
        {
          value: '飞机场',
          label: '飞机场',
        },
        {
          value: '火车站',
          label: '火车站',
        },
        {
          value: '客运中心',
          label: '客运中心',
        }
      ],
      cultureList:[
        {
          value: '学校',
          label: '学校',
        },
        {
          value: '医院',
          label: '医院',
        }
      ],
    }
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
    businessList() {
      return this.$store.state.select.businessList;
    }
  },
}
</script>

<style lang="scss">
#bigScreen-filter {
  position: absolute;
  z-index: 10;
  top: 3rem;
  right: 0;
  width: 27.11rem;
  display: flex;
  justify-content: space-between;

  li {
    width: calc(100% / 3 - 0.3rem);
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }

    .el-select {
      width: 100%;

      .el-input__inner {
        background-color: rgba(0, 168, 255, 0.23);
        border-color: transparent;
        color: white;
      }
    }
  }


}

</style>
